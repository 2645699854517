<template>
  <div>
    <form action="">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Request car service</p>
          <button type="button" class="delete" @click="$parent.close()" />
        </header>
        <section class="modal-card-body">
          <b-field label="Mechanic">
            <b-select
              placeholder="Select car mechanic"
              required
              v-model="carService.mechanic"
            >
              <option
                v-for="(user, index) in users"
                :key="index"
                :value="user['@id']"
              >
                {{ user.email }}
              </option>
            </b-select>
          </b-field>

          <b-field label="Select service date">
            <b-datetimepicker
              v-model.trim="carService.date"
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus
            >
            </b-datetimepicker>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-primary"
            :loading="isLoading"
            @click.prevent="createCarServiceRequest()"
          >
            Send request
          </button>
          <button class="button" type="button" @click="$parent.close()">
            Close
          </button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { httpClient } from '@/api/httpClient.js'

export default {
  props: {
    carId: {
      type: String
    }
  },
  data() {
    return {
      isLoading: false,
      users: [],
      carService: {
        date: null,
        mechanic: null
      }
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.isLoading = true

      httpClient
        .get('/users')
        .then(res => {
          this.isLoading = false
          this.users = res.data['hydra:member']
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    createCarServiceRequest() {
      this.isLoading = true

      httpClient
        .post('/car-services', {
          ...this.carService,
          owner: `/api/users/${this.$store.getters.getUserId}`,
          car: `/api/cars/${this.carId}`
        })
        .then(() => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Car service was requested`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'OK'
          })
          this.$emit('success')
          this.$parent.close()
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `${err.message}`,
            type: 'is-danger',
            position: 'is-top',
            actionText: 'Retry'
          })
        })
    }
  }
}
</script>

<style scoped></style>
