<template>
  <div>
    <Navbar />

    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>

    <PageTitle :title="'Car services'" :subtitle="'Request car services'" />

    <section class="section">
      <div class="container">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              <b-icon icon="car"></b-icon>
              Cars due service
            </p>
          </header>
          <div class="card-content">
            <b-table
              :data="cars"
              :paginated="true"
              per-page="10"
              current-page.sync="1"
              pagination-position="bottom"
              default-sort-direction="asc"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              hoverable
              striped
              selectable
            >
              <template slot-scope="props">
                <b-table-column
                  field="id"
                  label="ID"
                  sortable
                  numeric
                  width="50"
                >
                  {{ props.row.id }}
                </b-table-column>

                <b-table-column
                  field="licensePlate"
                  label="License plate"
                  sortable
                  searchable
                >
                  {{ props.row.licensePlate }}
                </b-table-column>

                <b-table-column field="make" label="Make" sortable searchable>
                  {{ props.row.make }}
                </b-table-column>

                <b-table-column field="model" label="Model" sortable searchable>
                  {{ props.row.model }}
                </b-table-column>

                <b-table-column
                  field="year"
                  label="Year"
                  sortable
                  numeric
                  width="40"
                >
                  {{ props.row.year }}
                </b-table-column>

                <b-table-column
                  field="mileage"
                  label="Mileage"
                  sortable
                  numeric
                  centered
                >
                  {{ props.row.mileage }}
                </b-table-column>

                <b-table-column field="date" label="Last service date" centered>
                  <span :class="isServiceDue(props.row.lastServiceDate)">
                    {{
                      new Date(props.row.lastServiceDate).toLocaleDateString()
                    }}
                  </span>
                </b-table-column>

                <b-table-column label="Request car service">
                  <button
                    class="button is-light"
                    v-if="canRequestCarService(props.row)"
                    @click.prevent="requestCarService($event, props.row.id)"
                  >
                    <b-icon icon="settings-outline"></b-icon>
                  </button>
                </b-table-column>
              </template>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>No cars due service</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import { httpClient } from '@/api/httpClient.js'
import Navbar from '@/components/Navbar.vue'
import PageTitle from '@/components/PageTitle.vue'
import Footer from '@/components/Footer.vue'
import CarServiceModal from '@/components/CarServiceModal.vue'

export default {
  name: 'CarServices',
  components: {
    Navbar,
    PageTitle,
    Footer
  },
  data() {
    return {
      isLoading: false,
      cars: []
    }
  },
  created() {
    this.getCars()
  },
  methods: {
    getCars() {
      this.isLoading = true

      httpClient
        .get('/cars')
        .then(res => {
          this.isLoading = false
          this.cars = res.data['hydra:member'].sort(
            (a, b) => new Date(a.lastServiceDate) - new Date(b.lastServiceDate)
          )
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    isServiceDue(lastServiceDate) {
      const today = new Date()
      const lastService = new Date(lastServiceDate)
      const oneMonthFromNow = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 30
      )
      const nextService = lastService.setFullYear(lastService.getFullYear() + 1)
      const twoMonthsFromNow = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 60
      )

      if (nextService > twoMonthsFromNow) {
        return 'tag is-success'
      } else if (
        nextService <= twoMonthsFromNow &&
        nextService >= oneMonthFromNow
      ) {
        return 'tag is-warning'
      } else if (nextService < oneMonthFromNow) {
        return 'tag is-danger'
      }
    },
    requestCarService(event, id) {
      event.stopPropagation()

      this.$buefy.modal.open({
        parent: this,
        component: CarServiceModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          carId: id.toString()
        },
        events: {
          success: () => {
            this.getCars()
          }
        }
      })
    },
    canRequestCarService(car) {
      return (
        car.carServices.length === 0 ||
        (car.carServices.length > 0 &&
          car.carServices.every(carService => carService.isFinished))
      )
    }
  }
}
</script>

<style></style>
